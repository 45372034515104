import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../../lib/context/AppContext'
import getTheme from '../../../lib/theme'

const StyledDesktopFooter = styled.footer`
width: 100%;
display: flex;
justify-content: center;
z-index: 2;
position: fixed;
bottom: 0;
left: 0;
background: ${getTheme().background};

.footer-container {
    display: flex;
    justify-content: center;
    max-width: ${getTheme().maxWidth}px;
    width: 100%;

    .footer-item {
      padding: ${getTheme().spacing * 2}px;
      color: #6E6E6E;
      font-size: ${getTheme().fontSize * 1.75}px;

      &.copyright {
        text-decoration: none;
      }
    }
  }
  border-top: 1px solid #D7D7D7;
`

const NavigationFooter = () => {
  const { setDesktopFooterHeight } = useAppContext()
  useEffect(() => {
    const element = document.querySelector('footer')
    const { height } = element.getBoundingClientRect()
    setDesktopFooterHeight(height)
  }, [])

  return <StyledDesktopFooter>
    <div className="footer-container">
      <span className="footer-item copyright" role="link" target="_blank">© 2023 Bayernwerk Netz GmbH</span>
      <a href="https://www.bayernwerk.de/de/ueber-uns/disclaimer.html" className="footer-item" role="link" target="_blank" rel="noreferrer">AGB</a>
      <a href="https://www.bayernwerk.de/de/ueber-uns/impressum.html" className="footer-item" role="link" target="_blank" rel="noreferrer">Impressum</a>
      <a href="https://www.bayernwerk.de/de/ueber-uns/datenschutz.html" className="footer-item" role="link" target="_blank" rel="noreferrer">Datenschutz</a>
      <a href="licenses.txt" className="footer-item" role="link" target="_blank" rel="noreferrer">Lizenzen</a>
      </div>
  </StyledDesktopFooter>
}

export default NavigationFooter
